module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  text: '#333333',
  primary: '#54BAD8',
  primaryLight: '#5AC5E6',
  primaryDark: '#3C8399',

  secondary: '#4EE6B6',
  secondaryLight: '#52F2C0',
  secondaryDark: '#236651',
};
